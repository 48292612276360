// zn.js
export default {
  home:{
    quantianshouhu:"All-day guard，Keep your sessions secure",
    baohuyonghuxinxi:"Protect users' information (text, picture, video, voice, call, etc.) from being leaked to others",
    liaojie:"Safety",
    bufenzhanshi:"Part of the APP page display",
    card1:"End-to-end encryption",
    cardtips1:"All messages are securely encrypted end-to-end to protect users' conversations and chats",
    card2:"Chat in the backroom",
    cardtips2:"Set the backroom password, you need to enter the correct password to enter the backroom",
    card3:"Application lock protection",
    cardtips3:"Activate the app lock to protect your private sphere from prying eyes",
    card4:"Reset protection",
    cardtips4:"Message stuck instantly, reset chat to protect your chat environment",
    card5:"Multi-person calls",
    cardtips5:"Promote deeper discussions and closer teamwork to improve communication efficiency",
    card6:"Ten thousand group",
    cardtips6:"Colorful chat, support for multiple voice calls, with strong group control rights",
    card7:"Voice call",
    cardtips7:"Keep your voice private and make voice messages and voice calls more fun and secure",
    card8:"Group manager setup",
    cardtips8:"Powerful group management rights, one-click operation, easy to cope with tens of thousands of group members",
    card9:"Mass messaging assistant",
    cardtips9:"To achieve one click to send messages to multiple group chats, eliminating tedious operations",
  },
  header:{
    shimida:"Shimida",
    lang:"English",
    kefu:"Customer service",
    tag1:"Home",
    tag2:"Light chat",
    tag3:"Safety",
    tag4:"Blog",
    tag5:"About",
    tag6:"Download",
    zh:"简体中文",
    en:"English",

  },
  foot:{
    shimida:"Shimida",
    jiamiapp:"An APP that encrypts information end-to-end, brings secure encrypted conversations in the new era,  constantly satisfying the spiritual pursuit of security awareness and pursuing higher quality social communication.",
    wechat:"Official WeChat",
    iDown:"iOS download",
    aDown:"Android download",
    saoyisao:"Mobile scan download",
    lianxi:"contact information",
    dianhua:"telephone number ",

    youxiang:"Email address",
    dizhi:"Address: No. 63 North Huangbian Road, Helong Street, Baiyun District, Guangzhou 6401",
    ggongzh:"WeChat Official Accounts：WeChat search [Shimida] ",
    fwyzc:"Support and Service ",
    yhxy:"User Agreement",
    yszc:"Privacy Policy ",
    gywm:"About Us",
    beian:"Guangzhou Youxun Network Technology Co., LTD. Copyright 2021-2022 | Guangdong ICP 19135552 | Value-added Telecom business License 44010602009534 | Guangdong B2-20210279 | Guangdong public Network Case: 44011102002976 | Developer: Guangzhou Youxun Network Technology Co., LTD. Version ",
    beian1:"广州友迅网络科技有限公司版权所有 | ",
    beianicp1:"粤ICP备18150474号-7 | ",
    beianicp2:"粤ICP备18150474号-8 | ",
    beiananbei:"粤公网安备: 44011102002976 | ",
    beian2:"开发者: 广州友迅网路科技有限公司 版本 ",
  },
  install:{
    card1:"iPhone",
    cardtips1:"Latest version installation Tutorial",
    card2:"Huawei",
    cardtips2:"Latest version installation Tutorial",
    card3:"Xiaomi",
    cardtips3:"Latest version installation Tutorial",
    card4:"OPPO",
    cardtips4:"Latest version installation Tutorial",
    card5:"VIVO",
    cardtips5:"Latest version installation Tutorial",
    card6:"Lenovo",
    cardtips6:"Latest version installation Tutorial",
    card7:"Meizu",
    cardtips7:"Latest version installation Tutorial",
    xiangq:"Learn more",

  },
  secureChat:{
    anquanchengaji:"What level of security does the Shimida APP use?",
    sidahexin:"Four core levels of security",
    jiamifangshi:"Details of the encryption method of the Shimida APP",
    tongdaojiami:"What encryption algorithms are used in channel encryption?",
    jiagoutu:"Channel Encryption Architecture Diagram",
    jimiyongdaode:"RSA, ECDHE, AES256_CBC, SHA256, SHA1, etc. used in channel encryption (see image on the right)",
    buzhou:"Steps",
    buzhou1:"1. Clients and servers generate random numbers first.",
    buzhou2:"2. The server sends a random number. ",
    buzhou3:"3. The client uses RSA to encrypt the client random number and sends it to the server. ",
    buzhou4:"4. The server uses RSA to decrypt random numbers on the client (The purpose of using RSA is to prevent man-in-the-middle attacks).",
    buzhou5:"5. The client and server use their own random number plus the other party's random number to generate temporary key TempKey and temporary offset TempIV (in this case, both parties hold the same TempKey and TempIV).",
    buzhou6:"6. Client servers use ECDHE to generate their own public and private key pairs.",
    buzhou7:"7. Clients use TempKey, TempIV to encrypt their ECDHE public key and send the ciphertext to the server. ",
    buzhou8:"8. The server receives the ECDHE public key ciphertext from the client and decrypts it. ",
    buzhou9:"9. The server uses TempKey, TempIV to encrypt its ECDHE public key, and uses RSA to encrypt ECDHE. ",
    buzhou10:"10. The server uses the ECDHE algorithm and uses the client ECDHE public key plaintext + server ECDHE private key plaintext to generate Sharekey. ",
    buzhou11:"11. The client receives the ECDHE public key ciphertext and signature from the server, and then decrypts and verifies the signature, if no problem, the client ECDHE private key plaintext + server ECDHE public key plaintext to generate ShareKey. ",
    buzhou12:"12. The server initializes the parameters and sends them to the client. The parameters include :",
    buzhou13:"• AuthKeyID： a temporary client identifier randomly generated by the server",
    buzhou14:"• MsgKey： The value of Sha256 after adding ShareKey, AuthkeyID, MsgID, SessionID, Salt, SeqNo and the real message content,This parameter is used for:",
    buzhou15:"a. Verification to prevent data tampering",
    buzhou16:"b. Used with ShareKey to generate an Aes256 key, IV, which is used to encrypt messages. ",
    buzhou17:"• MsgID：Time-related message ids to prevent replay and deduplication.",
    buzhou18:"• Salt：Unique salt number per login.",
    buzhou19:"• SeqNo：Used to prevent replay.",
    buzhou20:"13. Each subsequent message will have a different MsgID, SessionID, Salt, SeqNo and real message content; These varying values will cause the MsgKey to be different each time. The MsgKey, which is different each time, plus ShareKey, generates the corresponding 32 byte key and offset for each message. ",
    buzhou21:"14. ShareKey expires, and you need to re-negotiate the key after the expiration.",
    jiami:"Asymmetric encryption as shown below :",
    jiami1:"1. User generates ECDHE public and private keys and saves them locally.",
    jiami2:"2. Send the public key to the server and save the private key locally.",
    jiami3:"3. User logs in and gets updates on all of their friends' information, including each friend's public key.",
    jiami4:"4. Use your own private key + the public key of the other party to generate a ShareKey when sending and receiving messages.",
    jiami5:"5. Use ShareKey to generate the AES256 key for encryption and decryption.",
    shuangji:"The double ratchet algorithm is following :",
    shuangji1:"Alice(A) sends a message transmitting her new public key. Eventually, Bob(B) will receive one of the following messages and perform the second DH ratchet step; The DH output generated in each DH ratchet step is used to export new send and receive chain keys. As the parties take turns performing the DH ratchet step, they take turns introducing new send chains, and so on.",
    shujukuanq:"Server Database Security",
    fangchuangai:"The database on the server has been processed to prevent human tampering ",
    fangchuangai1:"The database has signature fields",
    fangchuangai2:"Generate ECDSA Public and private keys on server ",
    fangchuangai3:"When adding, deleting, or modifying database sensitive fields such as password, userID, and friend in server code, signatures are generated through ECDSA and updated to the signature field",
    fangchuangai4:"The server code reads and judges data such as password, userID, and friend through ECDSA verification signature.  Only after verification can the subsequent process proceed, otherwise an error will be reported to the client",
    fangchuangai5:"Encryption and signature protection for server program",
    miyaoanquan:"Key Security",
    miyaoanquan1:"The user's login password, secondary password, automatic login password, all adopt SRP algorithm, the server does not store the user's password.",
    miyaoanquan2:"The user's encrypted private key is only placed on the user's mobile phone.",
    miyaoanquan3:"The user can manually update the key of content encryption on the client at any time.",
    gongyaosiyao:"What are public key and private key?",
    erdamiyao:"Two important Encryption Keys",
    gongyaosiyaoxiangqing:"Public key (PubKey: public key) and private key (PriKey: private key). Public key and private key is a pair, if the public key is used to encrypt the data, only the corresponding private key can be decrypted. Because two different keys are used for encryption and decryption, this algorithm is called asymmetric encryption. For example, RSA and ECDHE used in the above encryption algorithm are asymmetric encryption algorithms",
    yuanli:"How does asymmetric encryption work?",
    yuanlijiexi:"Analysis of how encryption works",
    yuanli1:"1. A needs to send information to B, and both A and B need to generate a pair of public and private keys for encryption and decryption.",
    yuanli2:"2. A's private key is kept secret, and A's public key tells B; B's private key is kept secret, B's public key tells A.",
    yuanli3:"3. When A wants to send A message to B, A encrypts the message with B's public key because A knows B's public key. ",
    yuanli4:"4. A sends this message to B (the message has been encrypted with B's public key). ",
    yuanli5:"5. After B receives the message, B decrypts A's message with his private key. All others who receive this data cannot decrypt it, because only B has B's private key.",
    tabs1:"Channel Encryption ",
    tabs2:"Content Encryption Details ",
    tabs3:"Server Database Security, Key Security",
    Safetymeasures1:"Channel encryption",
    Safetymeasures2:"Content encryption",
    Safetymeasures3:"Service database security",
    Safetymeasures4:"Key security ",
  },
  HomeNews:{
    dongtaixinxi:"Dynamic information",
    anquanzhishi:"Safety Knowledge",
    dongtaixiangq:"Dynamic Details ",
    sousuoneirong:"Please enter search content",
    sousuo:"Search",
    zanwu:"No Content",

  },
  about:{
    fazhanlicheng:"Course of development ",
    help:"Help Center",
    changjianwenti:"Frequently Asked Questions",
    jiaocheng:"Using tutorial ",
  },
  download:{
    shimida:"Shimida",
    gf:"Official ",
    banben:"Current version :",
    gengxin:"Updated at : ",
    ios:"「 Open your iPhone camera, scan it and download it now」",
    anzhuo:"「 Open browser, sweep to download now」",
    xiazai:"Download and install ",
    shouquan:"Please click here to authorize before downloading, otherwise it cannot be used ",
    
    daxiao:"Size : ",
    chanpingongneng:"Specialized in securing user chat messages",
    kaifazhe:"Youxun Network Technology",
    yonghuxieyi:"《User Agreement》",
    yinsizhengce:"《Privacy Policy》",
    tips:"Tips: After downloading，please restart the App or the phone to use normally",

  },
};
<template>
	<div class="bottomRow">
		<!-- <div>广州友迅网络科技有限公司版权所有2021-2022粤ICP备18150474号</div>
		<div>增值电信业务经营许可证 粤B2-20210279 | 增值电信业务经营许可证 B2-20194184 | 粤公网案备44011102002976</div>
		<div style="display:flex;">
			<div style="margin-right: 16px;">开发者：广州友迅网络科技有限公司 版本:1.1.5 </div>
			<a href="https://h5.smdchat.com/webRule?key=iosPrivacyPolicy">
				<div style="color: #FFEDBF;cursor: pointer;" >
					查看软件权限<span style="text-decoration:underline;">《查看隐私协议》</span>
				</div>
			</a>
			
		</div> -->
		<div class="bottomRowContent">
			<div class="bottomColumn">
				<div class="bottomColumnRow">
					<el-image class="bottomlogo"  :src="require('@/images/home2/logo.png')"></el-image>
					{{ $t('foot.shimida') }}
				</div>
				<div  class="bottomColumnTips">
					{{ $t('foot.jiamiapp') }}
				</div>
				<div  class="bottomColumnCodeBoxs">
					<div  class="bottomColumnCode">
						<div class="bottomCode">
							<el-image class="bottomCodeImg"  :src="require('@/images/home/wxQrcode.png')"></el-image>
						</div>
						<div class="bottomCodeName">{{ $t('foot.shimida') }}</div>
						<div class="bottomCodeTips">{{ $t('foot.wechat') }}</div>
					</div>
					<div  class="bottomColumnCode">
						<div class="bottomCode has-padding">
							<el-image class="bottomCodeImg"  :src="qrcode"></el-image>
						</div>
						<div class="bottomCodeName">{{ $t('foot.iDown') }}</div>
						<div class="bottomCodeTips">{{ $t('foot.saoyisao') }}</div>
					</div>
					<div  class="bottomColumnCode">
						<div class="bottomCode has-padding">
							<el-image class="bottomCodeImg"  :src="qrcode"></el-image>
						</div>
						<div class="bottomCodeName">{{ $t('foot.aDown') }}</div>
						<div class="bottomCodeTips">{{ $t('foot.saoyisao') }}</div>
					</div>
				</div>
			</div>
			<div class="bottomColumn">
				<div class="bottomColumnTitle">{{ $t('foot.lianxi') }}</div>
				<div class="bottomColumnTitles">{{ $t('foot.dianhua') }}: 020-37324930</div>
				<div class="bottomColumnTitles">{{ $t('foot.youxiang') }}: youxun@myouxun.xom</div>
				<div class="bottomColumnTitles">{{ $t('foot.dizhi') }}</div>
				<div class="bottomColumnTitles">{{ $t('foot.ggongzh') }}</div>
			</div>
			<div class="bottomColumn">
				<div class="bottomColumnTitle">{{ $t('foot.fwyzc') }}</div>
				<a href="https://h5.smdchat.com/webRule?key=userAgreement">
					<div class="bottomColumnTitles">{{ $t('foot.yhxy') }}</div>
				</a>
				<a href="https://h5.smdchat.com/webRule?key=iosPrivacyPolicy">
					<div class="bottomColumnTitles">{{ $t('foot.yszc') }}</div>
				</a>
				<a href="/about">
					<div class="bottomColumnTitles">{{ $t('foot.gywm') }}</div>
				</a>
				
			</div>
		</div>
		<div class="bottomRowMsg">
			<!-- <a href="https://h5.smdchat.com/webRule?key=iosPrivacyPolicy"> -->
				<div>
					<!-- 广州友迅网络科技有限公司版权所有2021-2022 | 粤ICP 19135552号 | 增值电信业务经营许可证 44010602009534号 |  粤B2-20210279 | 粤公网案备: 44011102002976 | 开发者: 广州友迅网路科技有限公司 版本   -->
					{{ $t('foot.beian1') }} 
					<a href="https://beian.miit.gov.cn" target=“_blank” style="color: #666666;">
						{{ $t(`foot.beianicp${icp}`) }} 
					</a>
					<a href="https://beian.mps.gov.cn/#/query/webSearch?code=44011102002976" target=“_blank” style="color: #666666;">
						{{ $t('foot.beiananbei') }} 
					</a>
					{{ $t('foot.beian2') }}
					1.1.5
				</div>
			<!-- </a> -->
		</div>
	</div>
</template>
   
<script>
import { createDownloadQrcode } from '@/api/system'

export default {
	name: "HomeFoot",
	
	data() {
		return {
			activeName: this.index,
			icp:1,
			qrcode: '',
		}
	},
	mounted(){
		const currentHost = window.location.host;
		console.log(currentHost);
		if (currentHost=='www.imshimida.cn') {
			this.icp=2
		}
		this.getQrcode()
	},
	methods: {
		gotoxieyi() {
			window.location.href = "https://h5.smdchat.com/webRule?key=iosPrivacyPolicy"
		},
		getQrcode(){
			createDownloadQrcode().then(res => {
				this.qrcode = res.data.qrcode_url
			})
		},
	},
	
}
</script>
   
<style scoped>
.bottomRow {
	width: 100%;
	min-width:1440px;
	height: 510px;
	background: #151C24;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-family: PingFangSC-Medium, PingFang SC;
	
}
.bottomRowContent{
	width: 75vw;
	min-width: 1080px;
	padding-top: 50px;
	display: flex;
	justify-content: space-between;
}
.bottomColumnRow{
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 33px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}
.bottomlogo{
	width: 40px;
	height: 40px;
	margin-right: 12px;
}
.bottomColumnTips{
	max-width: 500px;
	font-size: 14px;
	font-weight: 400;
	color: #A1A5A8;
	line-height: 22px;
	margin-bottom: 35px;
}
.bottomColumnCodeBoxs{
	display: flex;
	max-width: 500px;
	justify-content: space-between;
}
.bottomColumnCode{
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 140px;
	height: 189px;
	background: rgb(32, 39, 46);
	border-radius: 8px;
}
.bottomCode{
	box-sizing: border-box;
	width: 100px;
	height: 100px;
	margin-top: 20px;
	margin-bottom: 10px;
	border-radius: 4px;
	overflow: hidden;
}
.bottomCode.has-padding {
	padding: 5px;
	background-color: #fff;
}
.bottomCodeImg{
	width: 100%;
	height: 100%;
}
.bottomCodeName{
	font-size: 14px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 20px;
	margin-bottom: 2px;
}
.bottomCodeTips{
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 17px;
	text-align: center;
}
.bottomColumnTitle{
	font-size: 14px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 20px;
	margin-bottom: 24px;
}
.bottomColumnTitles{
	font-size: 14px;
	font-weight: 400;
	color: #A1A5A8;
	line-height: 20px;
	margin-bottom: 32px;
	cursor: pointer;
	max-width: 400px;

}
.bottomRowMsg{
	margin-top: auto;
	height: 58px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	color: #666666;
	line-height: 17px;
	border-top: 1px solid ;
	border-color: rgb(33, 40, 46);
}
</style>
<template>
	<div class="main" ref="main" >
		<!-- 头部 -->
		<div class="mobileHead flex">
			<div class="mobileHeadL flex">
				<el-image class="mobileHeadLimg" :src="require(`@/images/phone/logo.png`)"></el-image>
				<div>事密达</div>
			</div>
			<div class="mobileHeadR" @click="showNavBtn()"  id="mobileHead">
				<el-image class="mobileHeadRimg" :src="require(`@/images/phone/headicon${showNav?`2`:`1`}.png`)"></el-image>

			</div>
		</div>
		<!-- 头部下拉盒子 -->
		<div class="mobileNavBox" v-if="showNav">
			<div class="mobileNav">
				<div class="mobileNavCover" @click="closeNav "></div>
				<div class="mobileNavItemBox">
					<div class="mobileNavItem flex" @click="changePage(i)" :class="pageIndex==i?'mobileNavItem_a':''" v-for="i in 5" :key="i">
						{{mobileNavItem[i-1]}}
					</div>
					
				</div>

			</div>
		</div>
		<!-- 内容组件盒子 -->
		<div>
			<mobile-home v-if="pageIndex==1" @changePage="changePage"></mobile-home>
			<mobile-secure v-if="pageIndex==2"></mobile-secure>
			<mobile-news v-if="pageIndex==3"></mobile-news>
			<mobile-help v-if="pageIndex==4"></mobile-help>
			<mobile-install v-if="pageIndex==5"></mobile-install>


		</div>

		<!-- 底部 -->
		<div class="mobileFoot">
			<div class="mobileFootRow">
				<el-image class="mobileFootRowimg" :src="require(`@/images/phone/logo.png`)"></el-image>
				<div>事密达</div>
			</div>
			<div class="mobileFootRow2">
				{{ $t('foot.jiamiapp') }}
			</div>
			<div class="mobileFootRow3">
				联系方式
			</div>
			<div class="mobileFootRow4">
				电话: 020-37324930
			</div>
			<div class="mobileFootRow4">
				邮箱: youxun@myouxun.xom
			</div>
			<div class="mobileFootRow4">
				地址: 广州市白云区鹤龙街黄边北路63号
			</div>
			<div class="mobileFootRow4">
				微信公众号: 微信搜索【事密达】
			</div>
			<div class="mobileFootRow3">
				支持与服务
			</div>
			<a href="https://h5.smdchat.com/webRule?key=userAgreement">
				<div class="mobileFootRow4">
					用户协议
				</div>
			</a>
			<a href="https://h5.smdchat.com/webRule?key=iosPrivacyPolicy">
				<div class="mobileFootRow4">
					隐私政策
				</div>
			</a>
			<div @click="changePage(4)" class="mobileFootRow4">
				关于我们
			</div>
			<div class="mobileFootRow3">
				公众号及移动端下载二维码
			</div>
			<div class="qrcodeBox flex">
				<div class="qrcodeItem">
					<el-image class="qrcodeItemimg" :src="require(`@/images/home/wxQrcode.png`)"></el-image>
					<div>事密达</div>
					<div class="qrcodeItemtips">官方微信</div>
				</div>
				<div class="qrcodeItem">
					<el-image class="qrcodeItemimg has-padding" :src="qrcode"></el-image>
					<div>iOS下载</div>
					<div class="qrcodeItemtips">手机扫一扫下载</div>
				</div>
				<div class="qrcodeItem">
					<el-image class="qrcodeItemimg has-padding" :src="qrcode"></el-image>
					<div>安卓下载</div>
					<div class="qrcodeItemtips">手机扫一扫下载</div>
				</div>
			</div>
			<div class="mobileFootLine1"></div>
			<div class="mobileFootBeian">
				广州友迅网络科技有限公司版权所有 | <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18150474号</a> | <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44011102002976" target="_blank">粤ICP备18150474号</a> | 开发者: 广州友迅网路科技有限公司 版本 1.1.5
			</div>
		</div>
		<div class="zwBox"></div>
		<!-- 固定底部栏 -->
		<div class="mobileFixed" >
			<div class="mobileFixedRow1"  id="downloadButton" @click="showLoading=true">
				<div class="mobileDownBtn" @click="gotoDownLoadPage(1)">
					<!-- <el-image class="mobileDownBtnimg" :src="require(`@/images/phone/downiosBtn.png`)"></el-image> -->
					iPhone版下载
				</div>
				<div class="mobileDownBtn mobileDownBtn_g" @click="gotoDownLoadPage(2)">
					<!-- <el-image class="mobileDownBtnimg" :src="require(`@/images/phone/downAndroidBtn.png`)"></el-image> -->
					Android版下载
				</div>
			</div>
			
			<!-- <div class="mobileFixedRow2">苹果下载打开后需重启APP或手机即可使用</div> -->
		</div>
		<div class="loading" v-if="showLoading">
			<van-loading type="spinner" color="#fff" />
		</div>
	</div>
</template>
<script>

import { createDownloadQrcode } from '@/api/system'
import { getVersionInfo } from '@/api/system'
import MobileHome from "@/views/Home/mobilePage/home"
import MobileSecure from "@/views/Home/mobilePage/secure"
import MobileNews from "@/views/Home/mobilePage/news"
import MobileHelp from "@/views/Home/mobilePage/help"
import MobileInstall from "@/views/Home/mobilePage/install"

export default {
	components: {
		MobileHome, 
		MobileSecure,
		MobileHelp,
		MobileInstall,
		MobileNews,
	},
	name: "MobileMain",
	props: {
		index: {
			type: String,
			default: '1'
		},
	},

	data() {
		return {

			downHrefIos: '',
			downHrefAndroid: '',
			isSafari: false,


			pageIndex: 1,

			headType:1,
			showNav:false,
			mobileNavItem:['首页','安全聊天','最新动态','关于事密达','安装教程'],
			showDownload:false,
			code:'',
			channel:'',
			showLoading:false,
			qrcode: '',
		}
	},

	mounted() {
		
		this.getPageData()
		if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
			this.isSafari = true
		}
		this.channel = this.getQueryVariable('channel')
		this.code = this.getQueryVariable('code')
		if (this.channel!='') {
			sessionStorage.setItem('channel', this.channel);
			sessionStorage.setItem('code', this.code);
		}
		this.getQrcode()
	},

	methods: {
		getQrcode(){
			createDownloadQrcode().then(res => {
				this.qrcode = res.data.qrcode_url
			})
		},
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");
			var data = {};
			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}
			if (value) {
				return data[value] ? data[value] : '';
			}
			return data;
		},
		closeNav(){
			this.showNav = false
		},
		
		showNavBtn(){
			this.showNav = !this.showNav
		},
		changePage(i){
			this.pageIndex = i	
			this.showNav = false
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth" // 平滑滚动到顶部
			});
		},
		
		gotoDownLoadPage(typeNum) {
			return
			let type = 'android'
			if (typeNum == 1) {
				type = 'ios'
			}
			this.$router.push({name:'download', query: { channel:this.channel,code:this.code,type }})

			// if (type == 1) {
			// 	// window.location.href = this.downHrefIos;
			// 	//window.location.href = 'https://h5.smdchat.com/ios_down';
			// 	window.location.href = 'https://apps.apple.com/app/id6462860670';
			// } else if (type == 2) {
			// 	window.location.href = this.downHrefAndroid;
			// }
		},
		getPageData() {
			getVersionInfo({ agent: "all" }).then(res => {
				this.downHrefIos = res.data.ios.download_url
				this.downHrefAndroid = res.data.android.download_url
				this.initULink(res.data.umeng_link_id)

			})
		},
		

		gotoxieyi() {
			window.location.href = "https://h5.smdchat.com/webRule?key=privacy_policy_ios_mj"
		},


		initULink(id) {
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://g.alicdn.com/jssdk/u-link/index.min.js";
			document.getElementsByTagName("head")[0].appendChild(script);
			let data = this.parseUrlParams()
			script.onload = function () {
				window.ULink({
					id,
					data: data,
					selector: "#downloadButton",
					// auto:true,
					timeout: 2000,
					lazy: false,
					useOpenInBrowerTips: 'default',
					proxyOpenDownload: function (defaultAction, LinkInstance) {
						// console.log(9999)
						// console.log(LinkInstance.solution.type)

						if (LinkInstance.solution.type === "scheme") {
							// qq或者微信环境特殊处理下

							if (ULink.isWechat || ULink.isQQ) {
								// 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开

								defaultAction();
							} else {
								window.location.href = LinkInstance.solution.downloadUrl;
							}
						} else if (LinkInstance.solution.type === "universalLink") {
							// universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟已于6月2日上线universalLink生成及重定向功能。
						}
					}
				})
			}
		},
		parseUrlParams(e) {
			var t = (e = e || window.location.href).indexOf("?") + 1,
				n = (e || "?").substring(t).replace(/\+/g, "%20"),
				r = n.indexOf("#");
			0 <= r && (n = n.substring(0, r));
			for (var i = n.split("&"), o = {}, a = 0; a < i.length; a++) {
				var s = i[a].split("="),
					c = decodeURIComponent(s[0] || ""),
					l = decodeURIComponent(s[1] || "");
				c && l && (void 0 === o[c] ? o[c] = l : "object" == typeof o[c] ? o[c].push(l) : o[c] = [o[c], l])
			}
			return o
		},


	},
	
}
</script> 
<style scoped>
.main {
	width: 100vw;
	background-color: #fff;
	position: relative;
}
.flex{
	display: flex;
	justify-content: center;
	align-items: center;
}
.mobileHead{
	width: 100vw;
	height: 50px;
	background: #FFFFFF;
	box-shadow: 0px 6px 8px 0px #ECF3FF;
	justify-content: space-between;
	padding: 0 4.27vw;
	box-sizing: border-box;
}
.mobileHeadL{
	font-size: 18px;
	font-weight: 600;
	color: #091D5A;
	line-height: 25px;
}
.mobileHeadLimg{
	width: 30px;
	height: 30px;
	margin-right: 8px;
}
.mobileHeadRimg{
	width: 20px;
	height: 20px;
}
.mobileFoot{
	width: 100vw;
	padding: 32px 4.27vw 16px;
	background: #151C24;
	box-sizing: border-box;
}
.mobileFootRow{
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 33px;
	display: flex;
	align-items: center;
}
.mobileFootRowimg{
	width: 40px;
	height: 40px;
	margin-right: 12px;
}
.mobileFootRow2{
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #A1A5A8;
	line-height: 20px;
	margin : 12px 0 0;
}
.mobileFootRow3{
	font-size: 14px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 20px;
	margin-top: 24px;
	margin-bottom: 4px;
}
.mobileFootRow4{
	margin-top: 12px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #A1A5A8;
	line-height: 17px;

}
.qrcodeBox{
	margin-top: 12px;
	justify-content: space-between;	
}
.qrcodeItem{
	width: 104px;
	height: 148px;
	background: rgba(255, 255, 255, .1);
	border-radius: 6px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 8px;
	box-sizing: border-box;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 17px;
}
.qrcodeItemimg{
	width: 88px;
	height: 88px;
	margin-bottom: 8px;
	border-radius: 4px;
}
.qrcodeItemimg.has-padding {
	box-sizing: border-box;
	padding: 5px;
	background-color: #fff;
}
.qrcodeItemtips{
	margin-top: 2px;
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 14px;
}
.mobileFootLine1{
	margin: 16px 0;
	width: 100%;
	height: 1px;
	background: #505050;
	opacity: 0.25;
}
.mobileFootBeian, .mobileFootBeian a{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 17px;
}
.zwBox{
	width: 100vw;
	height: 90px;
	background: #151C24;
}

.mobileFixed{
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 92px;
	/* background: rgba(255,255,255,0.9); */
	backdrop-filter: blur(10px);
	z-index: 99;
	padding: 16px 4.27vw 0;
	box-sizing: border-box;
	
}
.mobileFixedRow1{
	width: 100%;
	display: flex;
	justify-content: space-around;
}
.mobileFixedRow2{
	width: 100%;
	display: flex;
	justify-content: space-around;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #3486FF;
	line-height: 20px;
	margin-top: 10px;
}
.mobileDownBtn{
	width: 163px;
	height: 45px;
	background: #3486FF;
	border-radius: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 17px;
	}
.mobileDownBtn_g{
	background: #34D883;
}
.mobileDownBtnimg{
	width: 100%;
}
.mobileNavBox{
	width: 100vw;
	height: calc(100% - 50px);
	position: absolute;
	z-index: 99;
	top: 50px;
	left: 0;
}
.mobileNav{
	width: 100%;
	height: 100%;
	position: relative;
}
.mobileNavCover{
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.3);
}
.mobileNavItemBox{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
}
.mobileNavItem{
	height: 60px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 20px;
	border-bottom: 1px solid #eee;
}
.mobileNavItem_a{
	color: #3486FF;
}
.loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
</style>




















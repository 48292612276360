// zn.js
export default {
  home:{
    quantianshouhu:"全天守护 保障您的会话安全",
    baohuyonghuxinxi:"保护用户信息（文本、图片、视频、语音、通话等）避免泄漏到他人查看",
    liaojie:"了解安全技术",
    bufenzhanshi:"部分APP页面展示",
    card1:"端对端加密",
    cardtips1:"所有消息都经过安全端对端加密，保护用户会话聊天安全",
    card2:"密室会话",
    cardtips2:"设置密室密码，需输入准确密码才可进入到密室进行聊天",
    card3:"应用锁保护",
    cardtips3:"应用所锁守护你的私人领域，防止旁人窥探隐私",
    card4:"重置保护",
    cardtips4:"消息卡顿重置聊天，保护你的聊天生态",
    card5:"多人通话",
    cardtips5:"可促进更深入的讨论和更紧密的团队合作，提高沟通效率",
    card6:"超级万人群",
    cardtips6:"趣味畅聊，支持多人语音通话，有强大群管控权限",
    card7:"语音通话",
    cardtips7:"保护你的声音隐私，让语音消息、语音通话更有趣味安全",
    card8:"群管家设置",
    cardtips8:"强大的群管理权限，一键操作，应对万级群成员",
    card9:"群发助手",
    cardtips9:"实现一键发送消息给多个群聊，去除繁琐操作",
  },
  header:{
    shimida:"事密达",
    lang:"简体中文",
    kefu:"联系客服",
    tag1:"首页",
    tag2:"轻聊登录",
    tag3:"安全聊天",
    tag4:"最新动态",
    tag5:"关于事密达",
    tag6:"安装教程",
    zh:"简体中文",
    en:"English",
  },
  foot:{
    shimida:"事密达",
    jiamiapp:"一款对信息端对端加密的APP，在新时代带来的安全加密会话，不断满足用户对于安全意识的精神追求、更高品质的社交模式。",
    wechat:"官方微信",
    iDown:"iOS下载",
    aDown:"安卓下载",
    saoyisao:"手机扫一扫下载",
    lianxi:"联系方式",
    dianhua:"电话",

    youxiang:"邮箱",
    dizhi:"地址: 广州市白云区鹤龙街黄边北路63号6401",
    ggongzh:"微信公众号: 微信搜索【事密达】",
    fwyzc:"支持与服务",
    yhxy:"用户协议",
    yszc:"隐私政策",
    gywm:"关于我们",
    beian:"广州友迅网络科技有限公司版权所有 | 粤ICP 18150474号 | 粤公网案备: 44011102002976 | 开发者: 广州友迅网路科技有限公司 版本 ",
    beian1:"广州友迅网络科技有限公司版权所有 | ",
    beianicp1:"粤ICP备18150474号-7 | ",
    beianicp2:"粤ICP备18150474号-8 | ",
    beiananbei:"粤公网安备: 44011102002976 | ",
    beian2:"开发者: 广州友迅网路科技有限公司 版本 ",

  },
  install:{
    card1:"苹果",
    cardtips1:"苹果手机设备最新版本安装教程",
    card2:"华为",
    cardtips2:"华为手机设备最新版本安装教程",
    card3:"小米",
    cardtips3:"小米手机设备最新版本安装教程",
    card4:"OPPO",
    cardtips4:"OPPO手机设备最新版本安装教程",
    card5:"VIVO",
    cardtips5:"VIVO手机设备最新版本安装教程",
    card6:"联想",
    cardtips6:"联想手机设备最新版本安装教程",
    card7:"魅族",
    cardtips7:"魅族手机设备最新版本安装教程",
    xiangq:"了解详情",

  },
  secureChat:{
    anquanchengaji:"事密达APP采用了哪些安全层级？",
    sidahexin:"4大核心安全层级",
    jiamifangshi:"事密达APP加密方式详细信息",
    tongdaojiami:"通道加密中采用了哪些加密算法？",
    jiagoutu:"通道加密架构图",
    jimiyongdaode:"通道加密中使用到的 RSA, ECDHE, AES256_CBC, SHA256, SHA1等等(如右图)",
    buzhou:"步骤",
    buzhou1:"1. 客户端和服务器先产生随机数。",
    buzhou2:"2. 服务器下发随机数。",
    buzhou3:"3. 客户端用 RSA 对客户端随机数进行加密，并发送给服务器。",
    buzhou4:"4. 服务器用 RSA 解密客户端随机数（使用 RSA 的目的是防止中间人攻击）。",
    buzhou5:"5. 客户端，服务器用自己的随机数加上对方的随机数生成临时密钥 TempKey 和临时偏移量 TempIV (此时双方均持有相同的 TempKey和TempIV)。",
    buzhou6:"6. 客户端服务器均使用 ECDHE 生成各自的公私钥对。",
    buzhou7:"7. 客户端用 TempKey, TempIV 对自己的 ECDHE 公钥进行加密，并将密文发送给服务器。",
    buzhou8:"8. 服务器收到客户端的 ECDHE 公钥密文，并解密。",
    buzhou9:"9. 服务器使用 TempKey, TempIV 对自己的 ECDHE 公钥进行加密，并使用 RSA 对 ECDHE。",
    buzhou10:"10. 服务器使用 ECDHE 算法，使用客户端 ECDHE 公钥明文+服务器 ECDHE 私钥明文生成ShareKey。",
    buzhou11:"11. 客户端收到服务器的 ECDHE 公钥密文和签名，然后进行解密和验证签名，如果没有问题，就使用客户端 ECDHE 私钥明文+服务器ECDHE 公钥明文生成 ShareKey。",
    buzhou12:"12. 服务器初始化各个参数，下发给客户端,参数包含：",
    buzhou13:"• AuthKeyID： 服务器随机生成的客户端临时标识符",
    buzhou14:"• MsgKey： 由 ShareKey, AuthkeyID, MsgID, SessionID, Salt, SeqNo 以及真实的消息内容相加后进行 Sha256 后的值本参数用于：",
    buzhou15:"a. 进行防止数据篡改的验证",
    buzhou16:"b. 与 ShareKey 一起使用，生成 Aes256 key, IV，用来对各条消息进行加密。",
    buzhou17:"• MsgID：时间相关的消息 ID，用于防止重放和去重。",
    buzhou18:"• Salt：每次登录唯一的盐值。",
    buzhou19:"• SeqNo：用于防止重放。",
    buzhou20:"13. 后续每条消息均会有不同的 MsgID, SessionID, Salt, SeqNo 和真实的消息内容；这些变化的值会导致 MsgKey 每次都不同。 这个每次都 不同的 MsgKey 加上 ShareKey 可以为每一条消息生成对应的 32 字节密钥和偏移量。",
    buzhou21:"14. ShareKey 会过期，过期后需要重新进行密钥协商。",
    jiami:"非对称加密 如下图：",
    jiami1:"1. 用户生成 ECDHE 公私钥，保存在本地。",
    jiami2:"2. 将公钥发送到服务器，私钥保存在本地。",
    jiami3:"3. 用户登录，获取所有好友信息的更新，包含每个好友的公钥。",
    jiami4:"4. 发送消息，和接收消息时均采用自己的私钥+对方的公钥生成 ShareKey。",
    jiami5:"5. 使用 ShareKey 生成 AES256 的密钥，进行加密和解密。",
    shuangji:"双棘轮算法 如下：",
    shuangji1:"(AliceA)发送的消息传送了她的新公钥。 最终，(BobB)将收到以下消息之一，并执行第二个DH棘轮步骤； 在每个DH棘轮步骤中生成的DH 输出用于导出新的发送和接收链密钥。当各方轮流执行DH棘轮步骤时，他们轮流引入新的发送链，依此类推。",
    shujukuanq:"服务器数据库安全",
    fangchuangai:"服务器上的数据库做了防止人为篡改的处理",
    fangchuangai1:"数据库有签名字段",
    fangchuangai2:"服务器上生成 ECDSA 公私钥",
    fangchuangai3:"服务器代码中对数据库敏感字段，比如：password, userID, friend 等做增删改操作时均通过 ECDSA 生成签名，并更新到签名字段",
    fangchuangai4:"服务器代码对 password, userID, friend 等数据进行读取，判断等操作时进行 ECDSA验证签名，只有通过验证才能进行后续流程，否则 给客户端报错",
    fangchuangai5:"服务端程序进行加密和签名保护",
    miyaoanquan:"密钥安全",
    miyaoanquan1:"用户的登录密码，二次密码，自动登录密码，均采用 SRP 算法，服务器上不存储用户的密码。",
    miyaoanquan2:"用户的加密私钥仅放在用户手机。",
    miyaoanquan3:"用户可随时在客户端上手动更新内容加密的密钥。",
    gongyaosiyao:"什么是公钥和私钥？",
    erdamiyao:"两大加密密钥",
    gongyaosiyaoxiangqing:"公开密钥（PubKey:简称公钥）和私有密钥（PriKey:简称私钥）。公钥与私钥是一对，如果用公钥对数据进行加密，只有用对应的私钥才能解密。 因为加密和解密使用的是两个不同的密钥，所以这种算法叫作非对称加密算法。如上文加密中使用的RSA、ECDHE为非对称加密算法",
    yuanli:"非对称加密工作原理？",
    yuanlijiexi:"加密工作原理解析",
    yuanli1:"1. A要向B发送信息，A和B都要产生一对用于加密和解密的公钥和私钥。",
    yuanli2:"2. A的私钥保密，A的公钥告诉B；B的私钥保密，B的公钥告诉A。",
    yuanli3:"3. A要给B发送信息时，A用B的公钥加密信息，因为A知道B的公钥。",
    yuanli4:"4. A将这个消息发给B（已经用B的公钥加密消息）。",
    yuanli5:"5. B收到这个消息后，B用自己的私钥解密A的消息。其他所有收到这个数据的人都无法解密，因为只有B才有B的私钥。",
    tabs1:"通道加密",
    tabs2:"内容加密详解",
    tabs3:"服务器数据库安全、密钥安全",
    Safetymeasures1:"通道加密",
    Safetymeasures2:"内容加密",
    Safetymeasures3:"服务数据库安全",
    Safetymeasures4:"密钥安全",
  },
  HomeNews:{
    dongtaixinxi:"动态信息",
    anquanzhishi:"安全知识",
    dongtaixiangq:"动态详情",
    sousuoneirong:"请输入搜索内容",
    sousuo:"搜索",
    zanwu:"暂无内容",

  },
  about:{
    fazhanlicheng:"发展历程",
    help:"帮助中心",
    changjianwenti:"常见问题",
    jiaocheng:"使用教程",
  },
  download:{
    shimida:"事密达",
    gf:"官方",
    banben:"当前版本 : ",

    gengxin:"更新于 : ",
    ios:"「 打开苹果手机相机，扫一扫立即下载 」",
    anzhuo:"「 打开浏览器，扫一扫立即下载 」",
    xiazai:"下载安装",
    shouquan:"下载前请务必点击此处授权，否则无法使用",
   
    daxiao:"大小 : ",
    chanpingongneng:"产品功能：专注保护用户聊天信息安全",
    kaifazhe:"开发者名称：广州友迅网络科技有限公司",
    yonghuxieyi:"《用户协议》",
    yinsizhengce:"《隐私协议》",
    tips:"温馨提示：下载打开后请重启APP或手机即可正常使用",
    
  },
};
import request from '@/utils/request'
// 系统配置

// 获取版本信息
export function getVersionInfo(params) {
    return request({
        url: '/web/common/getVersionInfo',
        method: 'get',
        params
    })
}

// 下载打点
export function recordLogger(data) {
    return request({
        url: '/web/common/recordLogger',
        method: 'post',
        data
    })
}


// 最新动态
export function getHelpList(params) {
    return request({
        url: '/web/help/getList',
        method: 'get',
        params
    })
}

// 最新动态详情
export function getHelpInfo(params) {
    return request({
        url: '/web/help/info',
        method: 'get',
        params
    })
}

// 帮助中心列表
export function getHelpList2(params) {
    return request({
        url: '/web/help/getHelpList',
        method: 'get',
        params
    })
}

// 帮助中心列表
export function createDownloadQrcode(params) {
    return request({
        url: '/web/common/createDownloadQrcode',
        method: 'get',
        params
    })
}



// 埋点接口
export function webRecordLogger(data) {
    return request({
        url: '/web/common/recordLogger',
        method: 'post',
        data
    })
}

import Vue from "vue"
import App from "./App.vue"
import createRouter from "./router"
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './lang' // Internationalization



import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(ElementUI); 

Vue.config.productionTip = false



//创建createApp工厂函数
export default function createApp() {
    const router = createRouter()
    //创建vue实例
    const app = new Vue({
        store,
        router,
        i18n,
        render: h => h(App),
    })
    return { app, router, store}
}



 
 
<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="1"  :topColorW="topColorW"></home-header>
			<home-main @changeTop="changeTop"></home-main>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>


import HomeMain from "./components/HomeMain"
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"

export default {
	components: {
		HomeHeader, 
		HomeMain,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex:'1',
			topColorW:false,
		}
	},
	mounted() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		document.title = '事密达聊天官网-安全加密聊天软件'

	},
	methods:{
		changeTop(value){
			this.topColorW = value
		},
	},
};

</script>
<style scoped>
/* 头部样式 */


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox{
	display: none;
}
@media screen and (max-width:750px) {
	.pcBox{
		display: none;
	}
	.mobileBox{
		display: block;
	}
}

</style>
  
  
<template>
  <div >
    <div class="newsBox" v-if="pageType==1">
      <div class="newSearch newSearch2 flex" v-if="showInput">
        <input class="searchInput" ref="searchInput" v-model="listData.keyword" />
        <div class="searchInputR flex">
          <div class="searchShu"></div>
          <div class="searchBtn"  @click="getPageData">搜索</div>

        </div>
        
      </div>
      <div class="newSearch flex" v-else @click="showInputBtn">
        <el-image class="newSearchIcon" :src="require(`@/images/phone/newsearch.png`)"></el-image>
        搜索
      </div>
      <div class="topNav" v-if="tabData.length>0">
        <div class="topNavItem" @click="changeActiveName(tabData[0].id)" :class="tabData[0].id==activeId?'topNavItem_a':''">
          动态信息
        </div>
        <div class="w32"></div>
        <div class="topNavItem" @click="changeActiveName(tabData[1].id)" :class="tabData[1].id==activeId?'topNavItem_a':''">
          安全知识
        </div>
      </div>
      <div class="msgBox"  v-if="showList.length>0">
          <div class="msgItem" :class="index==(showList.length-1)?'noborder':''"  @click="gotoDetail(item.id)" v-for="(item,index) in showList" :key='index'>
            <div class="msgItemL">
              <div class="msgItemLTitle">{{item.title}}</div>
              <div class="msgItemLTime">{{item.created_at}}</div>
            </div>
            <div class="msgItemR"  v-if="item.cover" >
              <el-image class="msgItemRImg" :src="item.cover"></el-image>

            </div>
          </div>
          
      </div>
      <div class="msgItemNoBox" v-else-if="showList.length==0">
        <el-image class="msgItemNoImg"  :src="require(`@/images/phone/noSearch.png`)"></el-image>
      </div>

    
    </div>
    <div class="detailBox" v-else-if="pageType==2">
      <div class="detailNav" @click="backBtn">
        <span style="margin-right: 5px;">{{ detailData.type_name }}</span>><span class="detailNavR" style="margin-left: 5px;"> 动态详情</span> 
      </div>
      <div style="padding: 0 16px;box-sizing: border-box;">
        <div class="detailTitle">
          {{detailData.title}}
        </div>
        <div class="detailTime">
          {{changeTime(detailData.created_at)}}
        </div>
        <div class="detailHeng"></div>
        <div class="detailContent" v-html="detailData.content"></div>
      </div>
      

    </div>
  </div>
</template>
<script>
import { getHelpList,getHelpInfo } from '@/api/system'

export default {
  data() {
    return {
      searchText:'',
      showInput:false,
      topNavIndex:1,
			showList:[],
      listData:{
				type_id:'',
				keyword:'',
			},
			tabData:[],
			activeId:'',
      pageType:1,
			detailData:{},


    };
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    backBtn(){
      this.pageType=1
    },
    changeTime(time){
			const date = new Date(time*1000);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
    getDetailData(id){
			getHelpInfo({content_id:id}).then(res => {
				
				this.detailData =res.data
			})
		},
    gotoDetail(id){
      this.pageType=2
      this.getDetailData(id)
    },
    showInputBtn(){
      this.showInput=true
      this.$nextTick(() => {
          this.$refs.searchInput.focus();
      });
    },
    changeActiveName(id){
			this.activeId = id
			this.listData.type_id=id
			this.listData.keyword=''
			this.getPageData()

		},
    
    getPageData(){
			getHelpList(this.listData).then(res => {
				this.listData.type_id = this.activeId = res.type_id
				this.tabData = res.type_list
				this.showList = res.list
				if (res.type_id==res.type_list[0].id) {
					this.active = res.type_list[0]
				}else{
					this.active = res.type_list[1]
				}

			})
		},
  },
};
</script>
<style scoped>
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.newsBox{
  padding: 32px 4.27vw;
}
.newSearch{
  width: 100%;
  height: 36px;
  background: #F1F2F7;
  border-radius: 30px;
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8F8F96;
  line-height: 20px;
  box-sizing: border-box;
}
.newSearchIcon{
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.newSearch2{
  padding: 0 13px;

}
.searchInput{
  background: #F1F2F7;
  /* background: red; */
  width: 70%;
  border: none;
  color: #000;
}
.searchShu{
  width: 1px;
  height: 12px;
  background: #DBDCE2;
  margin-right: 12px;
}
.searchBtn{
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 18px;
}
.searchInputR{
  margin-left: auto;
}
.w32{
  width: 32px;
}
.topNav{
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 22px;
  border-bottom: 1px solid #EBEBEB;
  width: fit-content;
  margin: 25px 0 4px;
	box-sizing: border-box;

}
.topNavItem{
  padding-bottom: 12px;

}
.topNavItem_a{
  font-weight: 600;
  color: #3486FF;
  border-bottom: 2px solid #3486FF;
}
.msgItem{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  padding: 16px 0;
  box-sizing: border-box;
}
.noborder{
  border:none !important;

}
.msgItemL{
  display: flex;
  flex-direction: column;
  min-height: 19.333vw;

}
.msgItemLTitle{
  max-width: 55vw;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 22px;
  margin-bottom: 12px;
}
.msgItemLTitle100{
  max-width: 100%;

}
.msgItemLTime{
  margin-top: auto;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8F8F96;
  line-height: 16px;
}
.msgItemR{
  width: 29.07vw;
  height: 19.333vw;
  
}
.msgItemRImg{
  width: 29.07vw;
  height: 19.333vw;
  border-radius: 8px;
}
.msgItemNoBox{
  display: flex;
  justify-content: center;
  margin: 48px 0;
  width: 100%;
}
.msgItemNoImg{
  width: 180px;
  height: 180px;
}
.detailBox{
	padding: 16px 0;
	box-sizing: border-box;
}
.detailNav{
  padding: 0 16px;
	box-sizing: border-box;
  height: 31px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 17px;
  margin-bottom: 20px;
}
.detailNavR{
  color: #999;
}
.detailTitle{
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #01071A;
  line-height: 30px;
  letter-spacing: 1px;
}
.detailTime{
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  margin: 12px 0;

}
.detailHeng{
  width: 100%;
  height: 1px;
  background-color: #EBEBEB;
  margin-bottom: 20px;
}
</style>

  
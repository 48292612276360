<template>
  <div>
    <!-- 首页 -->
    <div v-if="pageType==1" class="helpBox">
      <div class="topNav">
        <div class="topNavItem" @click="changeNav(1)" :class="topNavIndex==1?'topNavItem_a':''">
          发展历程
        </div>
        <div class="w32"></div>
        <div class="topNavItem" @click="changeNav(2)" :class="topNavIndex==2?'topNavItem_a':''">
          帮助中心
        </div>
      </div>
      <div class="experienceBox" v-if="topNavIndex==1">
        <el-image class="experienceImg" :src="require(`@/images/phone/experienceImg.png`)"></el-image>

      </div>
      <div class="helpIframe" v-if="topNavIndex==2">
        <div class="helpList">
          <div class="helpListTitle">使用教程</div>
          <div class="helpItemBox">
            <div class="helpItem" @click="helpListBtn(index)" v-for="(item,index) in helpList" :key="item.id">
              <el-image class="helpItemIcon" :src="item.icon"></el-image>
              <div class="helpItemTitle">{{item.name}}</div>
            </div>
          </div>
          
        </div>
        <div class="questionList">
          <div class="questionTitle">常见问题</div>
          <div class="questionItemBox">
            <div class="questionItemRow" @click="questionListBtn(item.id,1)" v-for="item in questionList" :key="item.id">
              <div class="questionItemRowL">{{item.title}}</div>
              <div class="questionItemRowR">
                <el-image class="questionItemRowRIcon" :src="require(`@/images/phone/questionItemRowRIcon.png`)"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 教程详细页 -->
    <div v-else-if="pageType==2" class="tutorialBox">
      <div class="tutorialBoxNav" @click="pageType=1">
        帮助中心 > <span style="color: #999;margin-left: 5px;">使用教程</span>
      </div>
      <div class="tutorialBoxList">
        <div class="tutorialRow" @click="questionListBtn(item.id,2)" v-for="item in pageType2List" :key="item.id">
              <div class="tutorialRowL">{{item.title}}</div>
              <div class="tutorialRowR">
                <el-image class="tutorialRowRIcon" :src="require(`@/images/phone/questionItemRowRIcon.png`)"></el-image>
              </div>
            </div>
      </div>
    </div>
    <!-- 详情页 -->
    <div v-else-if="pageType==3" class="contentBox">
      <div class="contentBoxNav"  >
        <span @click="pageType=1" style="margin-right:5px;">帮助中心</span> > <span @click="pageType=navType" style="margin: 0 5px;">{{navType==1?'常见问题':'使用教程'}}</span> > <span style="color: #999;margin-left: 5px;">问题详情</span>
      </div>
      <div class="contentBoxTitle">{{contentTitle}}</div>
      <div class="contentBoxC" v-html="contents"></div>

    </div>
  </div>
 
</template>
<script>
import { getHelpList2,getHelpInfo } from '@/api/system'

export default {
  data() {
    return {
      topNavIndex:1,
			helpList:[],
			questionList:[],
			selectId:'',
			pageData:'',
      pageType:1,
      pageType2List:{},
      contents:'',
      navType:1,
      contentTitle:'',
    };
  },
  mounted() {
		this.getPageData()

  },
  methods: {
    getHelpInfoBtn(id){
			getHelpInfo({content_id:id}).then(res => {
				console.log(res);
				this.contentTitle = res.data.title
				this.contents = res.data.content
			})
		},
    questionListBtn(id,type){
      this.contents = ''
      this.contentTitle = ''
      this.getHelpInfoBtn(id)
      this.navType = type
      this.pageType = 3

    },
    helpListBtn(index){
      console.log(this.helpList[index].list);
      this.pageType2List  = this.helpList[index].list
      this.pageType = 2
    },
    getPageData(){
			getHelpList2().then(res => {
				console.log(res);
				this.questionList = res.data.normal[0].list
				this.helpList = res.data.use_course

			})
		},
    changeNav(index){
      this.topNavIndex = index
    }
  },
};
</script>
<style scoped>
.flex{
	display: flex;
	align-items: center;
	justify-content: center;
}
.helpBox{
	box-sizing: border-box;
}
.w32{
  width: 32px;
}
.topNav{
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 22px;
  border-bottom: 1px solid #EBEBEB;
  width: fit-content;
  margin: 4.27vw 3vw;
	box-sizing: border-box;

}
.topNavItem{
  padding-bottom: 12px;

}
.topNavItem_a{
  font-weight: 600;
  color: #3486FF;
  border-bottom: 2px solid #3486FF;
}
.experienceBox{
  margin-top: 24px;

  padding: 4.27vw 3vw;
	box-sizing: border-box;

  margin-bottom: 32px;
}
.experienceImg{
  width: 100%;
}
.helpIframe{
  padding: 3vw;
	box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
.helpListTitle{
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #01071A;
  line-height: 24px;
}
.helpItemBox{
  display: flex;
  flex-wrap: wrap;
  
}
.helpItem{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 86px;
  padding: 0 23px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 3px;
}
.helpItemIcon{
  width: 40px;
  height: 40px;
  margin-bottom: 8px;

}
.helpItemTitle{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 17px;
  white-space: nowrap;
}
.questionList{
  margin-top: 27px;
}
.questionTitle{
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #01071A;
  line-height: 24px;
}
.questionItemBox{
  margin-top: 16px;
  width: 100%;
  background: #F6F6F6;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 2px 13px;

}
.questionItemRow{
  display: flex;
  padding: 14px 0;
  align-items: center;

}
.questionItemRowR{
  margin-left: auto;
}
.questionItemRowRIcon{
  width: 6px;
  height: 10px;
}
.tutorialBox,.contentBox{
  padding: 16px 0;
  box-sizing: border-box;

}
.tutorialBoxNav,.contentBoxNav{
  padding: 0 16px ;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 17px;
  width: 100vw;
  height: 31px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
}
.tutorialBoxList{
  padding: 0 16px ;
  box-sizing: border-box;
  margin-top: 2px;
}
.tutorialRow{
  padding: 18px 0;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01071A;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.tutorialRowR{
  margin-left: auto; 
}
.tutorialRowRIcon{
  width: 6px;
  height: 11px;
}
.contentBoxTitle{
  margin-top: 26px;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #01071A;
  line-height: 28px;
  padding: 0 16px ;
  box-sizing: border-box;
}
.contentBoxC{
  padding: 0 16px ;
  box-sizing: border-box;

}
</style>

  
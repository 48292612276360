<template>
	<div style="overflow: hidden;">
		<div class="pcBox">
			<home-header index="6" :topColorW="topColorW"></home-header>
			<div class="homeContent">
				<div class="homeContentBgBox">
					<el-image class="homeContentBg" :src="require(`@/images/home2/installBg1${'_'+lang}.png`)"></el-image>
				</div>
				<div class="cardBox">
					<div class="card" v-for="i in 7 " :key="i">
						<div class="cardIconBox">
							<el-image class="cardIcon" :class="i==7?'cardIcon7':''" :src="require(`@/images/home2/tutorialIcon${i}.png`)"></el-image>
							
						</div>
						<div class="cardName">{{ $t(`install.card${i}`) }}</div>
						<div class="cardTips">{{ $t(`install.cardtips${i}`) }}</div>
						<router-link :to="{name:'installDetails', query: { pageType: i }}" class="cardBtn">{{ $t(`install.xiangq`) }}</router-link>
					</div>
					<div class="cardNo"></div>
				</div>
			</div>
			<home-foot></home-foot>

		</div>
		<div class="mobileBox">
			<mobile-main :index="pageindex"></mobile-main>

		</div>
	</div>
</template>
<script>
import HomeHeader from "./components/HomeHeader"
import HomeFoot from "./components/HomeFoot"
import MobileMain from "./components/MobileMain"
import HomeNews from "./components/HomeNews"


export default {
	components: {
		HomeHeader,
		HomeNews,
		HomeFoot,
		MobileMain
	},
	data() {
		return {
			pageindex: '1',
			topColorW: false,
			lang:this.$i18n.locale,
		}
	},

	mounted() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		window.addEventListener('scroll', this.updateScrollTop)
		document.title = '安装教程 - 事密达'
	},
	destroyed() {
		window.removeEventListener('scroll', this.updateScrollTop)
	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			this.lang = this.$i18n.locale;
		},
	},
	methods: {
		updateScrollTop() {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 兼容多个浏览器
			if (scrollTop==0) {
				this.topColorW=false
			}else{
				this.topColorW=true
			}
		},

	},
};

</script>
<style scoped>


.color {
	background-color: #2d3a4b;

}

/* 主区域 */
.main {

	/* 当内容过多时y轴出现滚动条 */
	/* background-color: red; */
}

.mobileBox {
	display: none;
}

@media screen and (max-width:750px) {
	.pcBox {
		display: none;
	}

	.mobileBox {
		display: block;
	}
}

.homeContent {
	padding-top: 10.8vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 88px;
	position: relative;
	min-height: 100vh;
	overflow: hidden;

}

.homeContentBgBox{
	position: absolute;
	width: 100vw;
	height: 626px;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	background: linear-gradient(180deg, #D6E8FF 0%, #FFFFFF 100%);
}
.homeContentBg {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	height: 626px;
	width: 2820px;
}
.cardBox{
	width: 1920px;
	position: relative;
	z-index: 2;
	padding: 290px 224px 0;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
}
.card{
	width: 336px;
	height: 402px;
	background: #FFFFFF;
	box-shadow: 0px 0px 68px 0px #E3EFFF;
	border-radius: 20px;
	margin: 0 16px 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

}
.cardNo{
	width: 336px;
	height: 402px;
}
.cardIconBox{
	margin-top: 8px;
	height: 176px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cardIcon{
	width: 104px;
	height: 100px;
}
.cardIcon7{
	width: 244px;
	height: 50px;
}
.cardName{
	font-size: 36px;
	font-weight: 500;
	color: #01071A;
	line-height: 50px;
}
.cardTips{
	font-size: 20px;
	font-weight: 400;
	color: #8F8F96;
	line-height: 28px;
	margin-top: 10px;
}
.cardBtn{
	width: 215px;
	height: 54px;
	border-radius: 27px;
	border: 1px solid #EBEBEB;
	font-size: 16px;
	font-weight: 400;
	color: #3486FF;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: auto;
	margin-bottom: 56px;
}
.cardBtn:hover{
	border: 1px solid #3486FF;
	cursor: pointer;
	
}
</style>
  
  
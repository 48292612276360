<template>
	<div @wheel="handleScroll" @scroll="getscroll">
		<div class="anima" >
			<div class="animaBgImg" :class="bgType == 1 ? 'animaBgImgStart' : bgType == 2 ? 'animaBgImgBack' : ''"></div>
			<div class="animaLbox1" :class="bgType == 1 ? 'animaLtextStart' : bgType == 2 ? 'animaLtextBack' : ''">
				<el-image class="animaText" alt="让聊天更轻松、更私密、更安全！ 专注保护用户聊天信息安全"
					:src="require(`@/images/home2/bg_shouye_wenan${'_'+lang}.png`)"></el-image>
				<div class="animaLbox1Btns">
					<div class="downLoadQrc downLoadQrc2 downLoadQrcS">
						<!-- <el-image class="downLoadQrcImg" :src="require('@/images/home/qrcodeAndroid.png')"></el-image> -->
						<el-image class="downLoadQrcImg" :src="qrcode"></el-image>
					</div>
					<router-link :to="{ name: 'download', query: { channel,code } }">
						<el-image @mouseenter="mouseenter(1)" @mouseleave="mouseleave(1)" class="centerBoxBtn1 centerBoxBtn"
							:src="require(`@/images/home2/btnNew${'_'+lang}.png`)"></el-image>
					</router-link>
					
					<div style="margin-top: 20px; color: #FA3232; font-size: 14px">*如安装存在问题，可移步各大应用商店搜索[事密达IM]下载使用!</div>					
				</div>
			</div>
			<div class="animaLbox2" :class="bgType == 1 ? 'animaLtextStart2' : bgType == 2 ? 'animaLtextBack2' : ''">
				<el-image class="animaText2" alt="事密达开启24小时端对端保密模式! 一键保护,让聊天隐私牢牢掌握在自己收里"
					:src="require(`@/images/home2/homeText2${'_'+lang}.png`)"></el-image>

			</div>

			<el-image :class="bgType == 1 ? 'animaRImgStart' : 'animaRImgBack'" class="animaRImg"
				:src="require('@/images/home2/animaImg1.png')"></el-image>
		</div>
		<div class="home_cardBox">
			<div class="home_cardBox_title">
				{{$t('home.quantianshouhu')}}
			</div>
			<div class="home_cardBox_titleS">
				{{$t('home.baohuyonghuxinxi')}}
			</div>
			<div class="home_cardRow">
				<div class="home_cardItem" v-for="i in 9" :key="i">
					<el-image class="home_cardItemImg" :src="require(`@/images/home2/homeCard${i}.png`)"></el-image>
					<div class="home_cardItemTitle">{{ $t(`home.card${i}`) }}</div>
					<div class="home_cardItemTitle2">{{ $t(`home.cardtips${i}`) }}</div>
					<div class="home_cardItemBtn" @click="gotoSecure">{{$t('home.liaojie')}}</div>
				</div>

			</div>
		</div>
		<div class="home_bottom">
			<div class="home_bottom_title">{{$t('home.bufenzhanshi')}}</div>
			<div class="home_bottom_img">
				<lottie class="appzsImg" :options="heartOptions" :height="'790px'" :width="'3240px'" />
			</div>

		</div>
	</div>
</template>


<script>

import lottie from '@/components/lottie'
import heartdata from "@/assets/home_bottom/data.json";

import { createDownloadQrcode,webRecordLogger } from '@/api/system'

export default {
	name: "AppMain",

	props: {
		index: {
			type: String,
			default: '1'
		},
	},
	components: {
		lottie,
	},
	data() {
		return {
			bgType: 0,
			channel: '',
			heartOptions: {
				animationData: heartdata,
				loop: true,
				autoplay: true,
			},
			heartanim: null,
			Direction: -1,
			animaing: false,
			topColorW:false,
			showQrcodeIos: false,
			showQrcodeAd: false,
			lang:this.$i18n.locale,
			code:'',
			channel:'',
			qrcode:'',
		}
	},
	watch: {
		'$i18n.locale'(newLocale, oldLocale) {
		// 在语言切换时执行逻辑
			// this.getI18n()
			this.lang = this.$i18n.locale;
		},
	},
	created() {
		
	},
	mounted() {
		window.addEventListener('beforeunload', this.handlePageRefresh);
		window.addEventListener('scroll', this.getscroll);
		this.channel = this.getQueryVariable('channel')
		this.code = this.getQueryVariable('code')
		if (this.channel!='') {
			sessionStorage.setItem('channel', this.channel);
			sessionStorage.setItem('code', this.code);
			this.recordLoggerBtn(this.channel,this.code)
		}else{
			this.channel = sessionStorage.getItem('channel');
			this.code = sessionStorage.getItem('code');
		}
		this.getQrcode(this.code,this.channel)
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handlePageRefresh);
		window.removeEventListener('scroll', this.getscroll);
	},
	methods: {
		recordLoggerBtn(code,channel){
			let data = {
				access_key:'www_channel_stat',
				ext:{
					code,
					channel
				},
			}
			webRecordLogger(data).then(res => {})
		},
		getQrcode(code,channel){
			createDownloadQrcode({code,channel}).then(res => {
				console.log(res);
				this.qrcode = res.data.qrcode_url
			})
		},
		getscroll(){
			// 处理滚动事件
			const scrollHeight = window.scrollY || window.pageYOffset;
      		// console.log('页面滚动高度：', scrollHeight);
			if(this.topColorW&&scrollHeight==0){
				this.topColorW=false
				this.$emit("changeTop", false);
			}else if (!this.topColorW&&scrollHeight!=0) {
				this.topColorW=true
				this.$emit("changeTop", true);
			}
		},
		handlePageRefresh() {
			// 滚动到页面顶部
			window.scrollTo(0, 0);
    	},
		gotoSecure(){
			this.$router.push({name:'secureChat'});
		},
		getQueryVariable(value) {
			var query = window.location.search.substring(1);
			query = query.split("&");
			var data = {};
			for (var i = 0; i < query.length; i++) {
				var item = query[i].split("=");
				data[item[0]] = item[1]
			}
			if (value) {
				return data[value] ? data[value] : '';
			}
			return data;
		},
		handleScroll(event){

			if (!this.animaing) {
				// console.log(event);
				// console.log(event.y);
				const scrollHeight = window.scrollY || document.documentElement.scrollTop;

				if ((this.bgType==0||this.bgType==2)&&scrollHeight==0&&event.deltaY > 0 ) {
					this.changAnima()
					event.preventDefault(); 
					
				}else if(this.bgType==1&&scrollHeight==0&&event.deltaY < 0) {
					this.changAnima()
					event.preventDefault(); 
					if(this.topColorW){
						this.topColorW=false
						this.$emit("changeTop", false);
					}
				}else if(scrollHeight>0) {
					if(!this.topColorW){
						this.topColorW=true
						this.$emit("changeTop", true);
					}
				}else if(scrollHeight==0) {
					if(this.topColorW){
						this.topColorW=false
						this.$emit("changeTop", false);
					}
				}
				
				

			}else{
				event.preventDefault(); // 阻止滚轮事件的默认行为（即禁止滚动）

			}
			
		},
		

		changAnima() {
			if (this.animaing) {
				return
			}
			this.animaing = true
			if (this.bgType == 1) {
				this.bgType = 2
			} else {
				this.bgType = 1
			}
			setTimeout(() => {
				this.animaing = false
			}, 700);
		},
		mouseenter(type) {
			if (type == 1) {
				this.showQrcodeIos = true
			} else if (type == 2) {
				this.showQrcodeAd = true
			}
		},
		mouseleave(type) {
			if (type == 1) {
				this.showQrcodeIos = false
			} else if (type == 2) {
				this.showQrcodeAd = false
			}
		},
		rollScroll(event) {
			let _that = this;
			// chrome、ie使用的wheelDelta，火狐使用detail
			let scrollVal = event.wheelDelta || event.detail;
			// 节流
			if (!_that.timeOut) {
				_that.timeOut = setTimeout(() => {
					_that.timeOut = null;
					scrollVal > 0
						? _that.$refs.carousel.prev()
						: _that.$refs.carousel.next();
				}, 300);
			} else {
			}
		},
		changeItem(e) {
			// console.log(e);
			if (e == 3) {
				return
			}
			let changPage = JSON.stringify(e + 1)
			let _that = this;
			_that.timeOut = setTimeout(() => {
				_that.timeOut = null;
				this.$emit("changPage", changPage)


			}, 300);
		},
	},

}
</script>

<style scoped>
.anima {
	width: 100%;
	height: 100vh;
	min-height: 600px;
	/* background-color: #3296FA; */
	position: relative;

}

.animaBgImg {
	width: 100%;
	height: 100vh;
	background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	background-color: rgb(226, 237, 254);

	background-position: top;
	background-repeat: no-repeat;
	background-size: auto 100vh;
	z-index: 0;
}

.animaLbox1 {
	position: absolute;
	top: 26vh;
	right: 53.23%;
	z-index: 9;
}

.animaText {
	width: 61.11vh;
	margin-bottom: 3.64vh;
}

.centerBoxBtn {
	width: 31vh;
	margin-right: 2.22vh;
}
.centerBoxBtn:hover{
	opacity: .8;
}

.animaRImg {
	position: absolute;
	top: 20vh;
	left: 48.2%;
	width: 61.11vh;
}

.animaLbox2 {
	position: absolute;
	/* top: 32.87vh; */
	right: 58.958%;
	opacity: 0;
	top: 60vh;

}

.animaText2 {
	width: 51.574vh;
}


.fade-enter,
.faed-leave-to {
	opacity: 0;
}

.fade-enter-active,
.faed-leave-active {
	transition: opacity 1s;
}


.animaBgImgStart {
	animation: animaBgImgStart .7s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaBgImgStart .7s ease-out 0s forwards normal;
}

@keyframes animaBgImgStart {
	0% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
		background-color: rgb(226, 237, 254);
	}

	50% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
	}

	100% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
		background-color: rgb(247, 249, 252);

	}
}

@-webkit-keyframes animaBgImgStart

/* Safari 与 Chrome */
	{
	0% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
		background-color: rgb(226, 237, 254);

	}

	50% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
	}

	100% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
		/* background-color: rgb(252, 253, 254); */
		background-color: rgb(247, 249, 252);


	}
}

.animaBgImgBack {
	animation: animaBgImgBack .7s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaBgImgBack .7s ease-out 0s forwards normal;
}

@keyframes animaBgImgBack {
	0% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
		background-color: rgb(252, 253, 254);
	}

	50% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	}

	100% {
		background-color: rgb(226, 237, 254);
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	}
}

@-webkit-keyframes animaBgImgBack

/* Safari 与 Chrome */
	{
	0% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye2.png');
		background-color: rgb(252, 253, 254);
	}

	50% {
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	}

	100% {
		background-color: rgb(226, 237, 254);
		background-image: url('https://down.smdchat.com/common/www/bg_shouye1.png');
	}
}

.animaLtextStart {
	animation: animaLtextStart .3s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaLtextStart .3s ease-out 0s forwards normal;
}

@keyframes animaLtextStart {
	0% {
		top: 26vh;
	}

	100% {
		top: 12.87vh;
		opacity: 0;
		display: none;
	}
}

@-webkit-keyframes animaBgImgBack

/* Safari 与 Chrome */
	{
	0% {
		top: 26vh;
	}

	100% {
		top: 12.87vh;
		opacity: 0;
		display: none;
	}
}

.animaLtextBack {
	animation: animaLtextBack .4s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaLtextBack .4s ease-out 0s forwards normal;
}

@keyframes animaLtextBack {
	0% {
		top: 12.87vh;
		opacity: 0;
		display: none;
	}

	100% {
		top: 26vh;
		display: block;
		opacity: 1;
	}
}

@-webkit-keyframes animaLtextBack

/* Safari 与 Chrome */
	{
	0% {
		top: 12.87vh;
		opacity: 0;
		display: none;

	}

	100% {
		top: 26vh;
		display: block;
		opacity: 1;
	}
}

.animaLtextStart2 {
	animation: animaLtextStart2 .3s ease-out .2s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaLtextStart2 .3s ease-out .2s forwards normal;
}

@keyframes animaLtextStart2 {
	0% {
		top: 60vh;
		display: none;
		opacity: 0;
	}

	100% {
		top: 32.87vh;
		opacity: 1;
		display: block;
	}
}

@-webkit-keyframes animaLtextStart2

/* Safari 与 Chrome */
	{
	0% {
		top: 60vh;
		display: none;
		opacity: 0;
	}

	100% {
		top: 32.87vh;
		opacity: 1;
		display: block;
	}
}

.animaLtextBack2 {
	animation: animaLtextBack2 .4s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaLtextBack2 .4s ease-out 0s forwards normal;
}

@keyframes animaLtextBack2 {
	0% {
		top: 32.87vh;
		opacity: 1;
		display: block;
	}

	100% {
		top: 60vh;
		display: none;
		opacity: 0;
	}

}

@-webkit-keyframes animaLtextBack2

/* Safari 与 Chrome */
	{
	0% {
		top: 32.87v;
		opacity: 1;
		display: block;
	}

	100% {
		top: 60vh;
		display: none;
		opacity: 0;
	}
}

.animaRImgStart {
	animation: animaRImgStart .7s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaRImgStart .7s ease-out 0s forwards normal;
}

@keyframes animaRImgStart {
	0% {
		top: 20vh;
		left: 48.2%;
		width: 61.11vh;
	}

	50% {
		top: 15.24vh;
		left: 44%;
		width: 80.33vh;
	}

	70% {
		top: 18.24vh;
		left: 45%;
		width: 73.33vh;
	}

	100% {
		top: 18.24vh;
		left: 45%;
		width: 73.33vh;
	}

}

@-webkit-keyframes animaRImgStart

/* Safari 与 Chrome */
	{
	0% {
		top: 20vh;
		left: 48.2%;
		width: 61.11vh;
	}

	70% {
		top: 15.24vh;
		left: 42%;
		width: 80.33vh;
	}

	100% {
		top: 18.24vh;
		left: 45%;
		width: 73.33vh;
	}
}

.animaRImgBack {
	animation: animaRImgBack .7s ease-out 0s forwards normal;
	/* Safari 与 Chrome: */
	-webkit-animation: animaRImgBack .7s ease-out 0s forwards normal;
}

@keyframes animaRImgBack {

	0% {
		top: 18.24vh;
		left: 45%;
		width: 73.33vh;
	}

	100% {
		top: 20vh;
		left: 48.2%;
		width: 61.11vh;
	}

}

@-webkit-keyframes animaRImgBack

/* Safari 与 Chrome */
	{
	0% {
		top: 18.24vh;
		left: 45%;
		width: 73.33vh;
	}

	100% {
		top: 20vh;
		left: 48.2%;
		width: 61.11vh;
	}
}

.home_bottom {
	width: 100%;
	margin-top: 48px;
	margin-bottom: 96px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.home_bottom_img {
	width: 100%;
	height: 790px;
	overflow: hidden;
	position: relative;
}

.home_bottom_title {
	margin-bottom: 48px;
	font-size: 36px;
	font-weight: 600;
	color: #01071A;
	line-height: 63px;
}

.appzsImg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.home_cardBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

}

.home_cardBox_title {
	margin-top: 96px;
	margin-bottom: 11px;
	font-size: 36px;
	font-weight: 600;
	color: #01071A;
	line-height: 65px;
}

.home_cardBox_titleS {
	font-size: 20px;
	font-weight: 400;
	color: #01071A;
	line-height: 28px;
	margin-bottom: 48px;

}

.home_cardRow {
	width: 1440px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

}

.home_cardItem {
	width: 464px;
	height: 390px;
	border-radius: 16px;
	border: 1px solid #E5E9EF;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 48px;
}

.home_cardItemImg {
	width: 106px;
	height: 116px;
	margin-top: 42px;
	transform: translateX(13px);
	margin-bottom: 2px;
}

.home_cardItemTitle {
	font-size: 26px;
	font-weight: 500;
	color: #01071A;
	line-height: 37px;
	margin-bottom: 12px;

}

.home_cardItemTitle2 {
	font-size: 20px;
	font-weight: 400;
	color: #8F8F96;
	line-height: 32px;
	max-width: 400px;
	text-align: center;
}

.home_cardItemBtn {
	margin-top: auto;
	margin-bottom: 40px;
	width: 179px;
	height: 54px;
	border-radius: 27px;
	border: 1px solid #EBEBEB;
	font-size: 17px;
	font-weight: 400;
	color: #3486FF;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.home_cardItem:hover {
	background-image: url('https://down.smdchat.com/common/www/cardBgimg.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: auto 100vh;
	box-shadow: 0px 0px 60px 0px #D4DDE5;
	border:none;
}

.home_cardItem:hover .home_cardItemTitle,
.home_cardItem:hover .home_cardItemTitle2 {
	color: #3486FF;

}

.home_cardItem:hover .home_cardItemBtn {
	border: 1px solid #3486FF;
}

.downLoadQrc {
	-webkit-tap-highlight-color: transparent;
	transition: 300ms ease-in-out;
	width:  17.96vh;
	height:  17.96vh;
	background: #FFFFFF;
	/* box-shadow: 0px 10px 30px 0px rgba(33, 159, 162, 0.2); */
	
	border-radius: 8px;
	z-index: 2;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 3.5vh;
}
.downLoadQrc1{
	left: 0;
	height:  21.94vh;
	bottom:  calc( -1.48vh - 21.94vh);
	
}
.downLoadQrcTips{
	width: 14.90vh;
}
.downLoadQrc2{
	left: calc( 17.96vh + 2.22vh );
}
.downLoadQrcImg {
	width:  15.5vh;
	height: 15.5vh;
	background: #F5F5F5;
	/* border-radius: 12px; */
	overflow: hidden;
}

.downLoadQrcR {
	transform: translateX(12px);
}

.downLoadQrcS {
	opacity: 1;
}
.tips{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #091D5A;
	line-height: 17px;
}
</style>

